export const API_URL = 'https://api.airtable.com';
export const API_BASE = 'appLkA0KSuHuaU6l8';
export const API_KEY =
  'patWkfVQbdnLHKSrh.8c03b537712259b2afab96e20c0d66c015f3c0aac13b9e82727ff0be11bfc042'; // 'keyrHpbJFFaF4wYSK';
export const WEBCONFIG_HOME_URL = 'https://dt-prodconfig.agentur-id.de/';
export const DB_LOGIN_API =
  'https://universal.dasbad3.de/universalbackend/public/api/authentication/webconfig_login';
export const DB_API =
  'https://universal.dasbad3.de/universalbackend/public/api/craftsmenconfiguration_sec/';
export const DOWNLOAD_API =
  'https://universal.dasbad3.de/universalbackend/public/api/bucket/downloadDigicoachImage/digicoachlogo/';
export const UPLOAD_API =
  'https://universal.dasbad3.de/universalbackend/public/api/bucket/uploadDigicoachImage';
export const ZOHO_API =
  'https://universal.dasbad3.de/universalbackend/public/api/craftsmenconfiguration/finish/';
export const ZOHO_EDIT_API =
  'https://universal.dasbad3.de/universalbackend/public/api/craftsmenconfiguration/editUpdate/';
export const ZOHO_API_SAVE_CATEGORY =
  'https://universal.dasbad3.de/universalbackend/public/api/craftsmenconfiguration/saveCategory/';
export const ZOHO_EDIT_API_CATEGORY =
  'https://universal.dasbad3.de/universalbackend/public/api/craftsmenconfiguration/saveCategory/';
export const WEBCONFIG_TRADE_MODE = 'DT';
export const SHOW_WEBSITE_BUY = true;
export const SHOW_PRICE_TABLE = false;
